import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useReport() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", thStyle: { width: "40%" } },
    { key: "fileSize" },
    { key: "recentDate" },
    { key: "actions" },
  ];

  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchData = [
    {
      id: 50,
      name: "p.txt",
      fileSize: "0 KB",
      recentDate: "11 May 2023",
    },
    {
      id: 49,
      name: "default.jpg",
      fileSize: "1.7 MB",
      recentDate: "06 Apr 2022",
    },
  ];

  const resolveVariant = (fileName) => {
    if (fileName.includes(".doc") || fileName.includes(".docx")) return "blue";

    if (fileName.includes(".pdf")) return "danger";
    if (
      fileName.includes(".jpg") ||
      fileName.includes(".png") ||
      fileName.includes(".jpeg")
    )
      return "green";
    return "green";
  };

  const resolveIcon = (fileName) => {
    if (
      fileName.includes(".pdf") ||
      fileName.includes(".doc") ||
      fileName.includes(".docx")
    )
      return "FileTextIcon";
    if (
      fileName.includes(".jpg") ||
      fileName.includes(".png") ||
      fileName.includes(".jpeg")
    )
      return "ImageIcon";
    return "FileIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchData,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveVariant,
    resolveIcon,
    resolveUserStatusVariant,
    refetchData,
  };
}
